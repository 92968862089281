import React from "react"
import { Link } from "gatsby"
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import Layout from "../components/layout"
import ContactCTA from "../components/cta-contact"
import Testimonials from "../components/testimonials"
import HelpList from "../components/help-list"


import Nav from "react-bootstrap/Nav"
import Jumbotron from "react-bootstrap/Jumbotron"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import Button from "react-bootstrap/Button"

import talking from "../images/talking.svg"
import directions from "../images/directions.svg"
import map from "../images/map.svg"
import alex from "../images/alex.png"
import growth from "../images/growth.jpg"
import begin from "../images/begin.jpg"

import faLongArrowAltRight from "../images/fa-long-arrow-alt-right-solid.svg"
import faExternalLink from "../images/fa-external-link-solid.svg"

const About = props => {
  return(
    <Layout>
      <GatsbySeo
        title="About"
        description="About integrative therapy, meet your therapist and discover what I can help with. Find out what to expect at your first appointment and how much counselling costs"
        canonical="https://yourtherapydoncaster.co.uk/about"
        openGraph={{
         url: "https://yourtherapydoncaster.co.uk/about",
         title: "About",
         description: "About integrative therapy, meet your therapist and discover what I can help with. Find out what to expect at your first appointment and how much counselling costs",
         site_name: "YourTherapy",
       }}
      />
      <Jumbotron fluid className="bg-pattern pt-md-3 ">
        <Container>
          <Row xs={1} md={2}>
            <Col lg={{span:4,offset:1}}>
              <h1 class="text-center text-md-left">About YourTherapy</h1>
              <Nav className="d-flex flex-column align-content-center align-content-md-start">
                <Nav.Item className="mb-2">
                  <Link to="#what-is-integrative-therapy">What is integrative therapy?</Link>
                  <Image width="20" height="20" src={faLongArrowAltRight} style={{paddingLeft:5}} alt=""/>
                </Nav.Item>
                <Nav.Item className="mb-2">
                  <Link to="#meet-alex">Meet your therapist</Link>
                  <Image width="20" height="20" src={faLongArrowAltRight} style={{paddingLeft:5}} alt=""/>
                </Nav.Item>
                <Nav.Item className="mb-2">
                  <Link to="/about#things-i-can-help-with">Things I can help with</Link>
                  <Image width="20" height="20" src={faLongArrowAltRight} style={{paddingLeft:5}} alt=""/>
                </Nav.Item>
                <Nav.Item className="mb-2">
                  <Link to="#what-to-expect">What to expect</Link>
                  <Image width="20" height="20" src={faLongArrowAltRight} style={{paddingLeft:5}} alt=""/>
                </Nav.Item>
                <Nav.Item className="mb-2">
                  <Link to="#prices">Price</Link>
                  <Image width="20" height="20" src={faLongArrowAltRight} style={{paddingLeft:5}} alt=""/>
                </Nav.Item>
                <Nav.Item className="mb-2">
                  <Link to="#find-us">Directions</Link>
                  <Image width="20" height="20" src={faLongArrowAltRight} style={{paddingLeft:5}} alt=""/>
                </Nav.Item>
              </Nav>
            </Col>
            <Col>
              <Image src={talking} fluid alt=""/>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <Container fluid="xl" id="what-is-integrative-therapy">
        <Row className="my-3">
          <Col xs={12} lg={{span:10, offset:1}} className="mt-5 text-center">
            <h2>What is integrative therapy?</h2>
          </Col>
          <Col xs={12} sm={{span:10, offset:1}} md={{span:5, offset:1, order:'last'}} className="p-3 pl-sm-0 pr-lg-5">
            <p>Integrative therapy is a form of talking therapy that draws together techniques from different therapeutic approaches.</p>
            <p>It’s centered around the idea that as we are all individual beings with unique thoughts and experiences; no single form of therapy will work for all people. </p>
            <p>This means that an approach will be tailored to you based on your individual wants and needs, rather than trying to fit you into a predefined system that may not be suited to you or may not be particularly helpful.</p>
            <p>Some theories and techniques used may include Humanistic Therapies, Cognitive Behavioural Therapy (CBT) and Psychodynamic Psychoanalytical Therapies.</p>
            <Button href="https://www.bacp.co.uk/about-therapy/types-of-therapy/" target="_blank" rel="noopen noreferrer" className="p-2 mb-3" variant="outline-dark">
              Read more about different types of therapy on the BACP website
              <Image width="10" height="10" src={faExternalLink} className="ml-1 mr-1 small" alt="" />
            </Button>
            <p>One of the most important parts of this type of therapy is to help you feel recognised and understood; developing and maintaining trust between you and me, the therapist.
            Read on to get some more information on me and my background.</p>
          </Col>
          <Col xs={12} sm={{span:8, offset:2}} md={{span:6,offset:0}} lg={{span:4, offset:1}} className="px-5 px-sm-4 d-flex flex-column" >
            <div class="bg-pattern pt-5 pr-5 pb-3 pl-1 rounded-pill">
              <Image src={growth} fluid alt="" />
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid="xl" id="meet-alex">
        <Row className="my-3">
          <Col xs={12} lg={{span:10, offset:1}} className="mt-5 text-center">
            <h2>Meet your therapist</h2>
          </Col>
          <Col xs={12} sm={{span:10, offset:1}} md={{span:5, offset:1}} className="p-3 pl-sm-0 pr-lg-5">
            <p>Hi, I’m Alex and I’ll be your counsellor.</p>
            <p>I have always cared deeply about others and helping people to live better lives.</p>
            <p>I am a professionally qualified integrative
            therapist and a member of the BACP (British Association for Counselling and Psychotherapy).
            I have experience helping with a wide range of problems and feelings you may be experiencing.&nbsp;
            <Link to="/about#things-i-can-help-with" className="font-weight-bolder">
              Please see the list of some of the things I can help with here
              <Image width="20" height="20" src={faLongArrowAltRight} style={{paddingLeft:5}} alt="" />
            </Link>
            </p>
            <p>My aim as a counsellor is to never judge, listen deeply and value you as an individual.
            I will always draw from my specialist training and experience to help you work through whatever comes up in our sessions.
            I feel strongly that counselling is never a weakness; it is always a strength and my hope is that with me,
            you will feel you have the help and support needed to reach your goals.</p>
          </Col>
          <Col xs={12} sm={{span:10, offset:1}} md={{span:5, offset:0}} className="px-5 px-sm-4 d-flex flex-column" >
            <Image src={alex} fluid alt="" />
          </Col>
        </Row>
      </Container>
      <HelpList />
      <Container fluid="xl" id="what-to-expect">
        <Row className="my-3">
          <Col xs={12} lg={{span:10, offset:1}} className="mt-5 text-center">
            <h2>What to expect</h2>
          </Col>
          <Col xs={12} sm={{span:10, offset:1}} md={{span:5, offset:1, order:'last'}} xl={{span:6,offset:1}}className="p-3 pl-sm-0 pr-lg-5">
            <p>When you get in touch to arrange an appointment, we’ll decide on the best way for us to hold our session together.
            This could be in-person in my private therapy space or through video call.</p>
            <p>Our first session will be one of discovery, where I’ll get to know you and you’ll get to know me.
            It’s normal for you to feel nervous about our first session together, however I hope you will find that I will
            help by putting you at ease. I will start by welcoming you into the space and will share with you some information
            about the counselling contract and what you can expect from our first session together.
            I will answer any questions you might have and then the session is yours to use as you wish.
            We might start by exploring how you’ve been feeling recently and what has brought you to counselling.
            I will be right here with you to support and guide you through at a pace that suits.</p>
            <p>Before the end of the session I will check in with you to see how you feel the first session has gone.
            If we both feel that therapy would be beneficial for you, and you would like to continue working together,
            we will discuss how to move forward. You might have an idea about how many sessions you would like and how
            regularly you would like them. This is your decision to make and I am happy to explore this with you.</p>
            <p>Our work together will always be fully confidential with some exceptions. These will be explained to you in our first session together.&nbsp;
              <a href="https://www.bacp.co.uk/events-and-resources/ethics-and-standards/" target="_blank" rel="noreferrer" class="font-weight-bolder">
              You can also read about the BACP Ethical Framework I follow here
              <Image width="10" height="10" src={faExternalLink} className="ml-1 mr-1 small" alt=""/>
              </a>
            </p>
          </Col>
          <Col xs={12} sm={{span:8, offset:2}} md={{span:6,offset:0}} lg={{span:4, offset:1}} xl={{span:5,offset:0}} className="px-5 px-sm-4 d-flex flex-column" >
            <div class="bg-pattern py-5 pl-5 pr-1 rounded-pill">
              <Image src={begin} fluid alt=""/>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid id="prices" className="bg-pattern py-5 d-flex justify-content-center">
        <div class="card text-center shadow-lg">
          <div class="card-header">
            Price
          </div>
          <div class="card-body">
            <h5 class="card-title">£45</h5>
            <p class="card-text">per 1 hour session</p>
            <Button href="/contact/" className="py-2 px-4 mx-auto mt-2" variant="outline-dark">
              Book
            </Button>
          </div>
          <div class="card-footer text-muted">
            In person, online or over the phone
          </div>
        </div>
      </Container>
      <Testimonials />
      <Container fluid="xl" id="find-us">
        <Row className="my-3">
          <Col xs={12} lg={{span:10, offset:1}} className="mt-5 mb-3 text-center">
            <h2>Directions</h2>
          </Col>
          <Col xs={12} sm={{span:10, offset:1}} md={{span:5, offset:1, order:'last'}} className="p-3 pl-sm-0 pr-lg-5">
            <p class="text-uppercase font-weight-bolder">Via car or public transport</p>
            <p>I'm based in Cusworth, Doncaster, which is 5 minutes from junction 37 of the A1. My full address and directions will be sent ahead of our first session together, or can be provided upon request.
            </p>
          </Col>
          <Col xs={12} sm={{span:10, offset:1}} md={{span:5, offset:1}} className="px-5 px-sm-4 d-none d-md-flex flex-column justify-content-center" >
            <div style={{width: "100%"}}>
              <Image src={map} fluid alt=""/>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row xs={1}>
          <Col md={{span:8, offset:2}} className="d-flex flex-column align-content-center mt-5">
            <Image src={directions} alt="" fluid/>
          </Col>
        </Row>
      </Container>
      <ContactCTA />
    </Layout>
  )
}

export default About
